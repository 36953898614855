input {
  border: 0;
  outline: 0;
}
input:focus {
  outline: none !important;
}
  /* Removed !important as this affects all dropdown styles in antd components, in this codebase, suggests to create unique className (e.g. .ant-picker-dropdown .followedByAnotherClassName*/
  /*Currently Unused, it's ok to disable*/
/* .ant-picker-dropdown {
  z-index: 2000;

  background-color: red;
} */

#merchant_datepicker,
.ant-picker {
  border-radius: 8px;
}
