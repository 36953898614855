input {border:0;outline:0;}
input:focus {outline:none!important;}

.ant-picker-range {
  flex-direction: column;
  gap: 5;
}

.ant-picker-range-separator {
  display: none;
}

#pagination_container_advocacy_availability {
  display: none !important;
  height: 20px !important;
}

#view_advocacy_availability .ant-picker-suffix {
  color: #00CBFF !important;
}

#view_advocacy_availability .ant-picker {
  padding: 11px !important;
  border-radius: 8px !important;
}

