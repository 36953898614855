input {border:0;outline:0;}
input:focus {outline:none!important;}

/* #pagination_container_availability_consult_later {
  display: none !important;
}

.ant-picker-range {
  flex-direction: column;
  gap: 5;
}

.ant-picker-range-separator {
  display: none;
}

#pagination_container_availability {
  display: none !important;
  height: 20px !important;
}

#view_availability_consult_later .ant-picker-suffix, #view_availability_consult_later p {
  color: #0E4D94 !important;
}

#view_availability_consult_later .ant-picker {
  padding: 11px !important;
  border-radius: 8px !important;
  border: 2px solid #C9C9C9 !important;
} */

#availability_schedule {
  overflow: hidden;
}

#availability_schedule .ant-picker-suffix {
  color: #0E4D94 !important;
  font-size: 20px !important;
}

#availability_schedule .ant-picker {
  padding: 15px !important;
  border-radius: 8px !important;
  border: 2px solid #C9C9C9 !important;
}

#availability_schedule .ant-picker-clear {
  border-radius: 8px !important;
  color: #0E4D94;
  font-size: 20px !important;
}
