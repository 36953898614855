input {
  border: 0;
  outline: 0;
}
input:focus {
  outline: none !important;
}

select {
  border: 0;
  outline: 0;
}
select:focus {
  outline: none !important;
}

iframe {
  width: 100%;
  height: 100%;
}
