input {border:0;outline:0;}
input:focus {outline:none!important;}

.ant-picker-range {
  flex-direction: column;
  gap: 5;
}

.ant-picker-range-separator {
  display: none;
}

/* #advocacy_schedule {
  display: none !important;
  height: 20px !important;
} */

#advocacy_schedule .ant-picker-suffix {
  color: #00CBFF !important;
}

#advocacy_schedule .ant-picker {
  padding: 11px !important;
  border-radius: 8px !important;
}

