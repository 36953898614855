footer {
  background: #f6f6f6;
  padding: 5em 0;
}

h4 {
  font-size: 1.5rem;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}

.footer-contact {
  /* text-align: left; */
}

.footer-contact img {
  height: 84px;
  width: auto;
  margin: 0 0 2em;
}

.footer-contact p {
  color: #0274be;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  margin: 0;
  padding: 0 0 1em;
  text-decoration: none;
}
.footer-contact p a {
  text-decoration: none;
  padding: 0 0 1em;
  font-family: 'Poppins', sans-serif;
  text-align: left;
  font-weight: 600;
  color: #0274be;
  margin: 0;
}

.footer-quick-links {
  text-align: left;
}

.footer-quick-links  h4 {
  color: #0274be;
  text-transform: uppercase;
  padding: 1em 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.footer-quick-links .social-cont {
  display: flex;
  margin: 1em 0 0;
  text-align: center;
}

.footer-quick-links .social-cont a {
  margin: 0 0.5em 0 0;
}

.footer-quick-links .social-cont img {
  width: 25px;
  height: 25px;
  transform: scale(1);
  transition: transform 0.2 ease;
}

.footer-quick-links .social-cont img:hover {
  transform: scale(1.1);
}

.footer-links a {
  color: #0274be;
  display: block;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  text-align: left;
  padding: 0 0 1em;
}

.footer-quick-links .footer-download-links {
  margin: 0 0 2em;
}

.footer-quick-links .footer-download-links a {
  height: auto;
  display: block;
  margin: 0 0 1em;
  transform: scale(1);
  transition: transform 0.2s ease;
  width: 100%;
  max-width: 200px;
}

.footer-quick-links .footer-download-links a:hover {
  transform: scale(1.1);
}

.footer-quick-links .footer-download-links img {
  width: 100%;
  height: auto;
}

@media (max-width: 575px) {

  h4 {
    font-size: 16px;
  }

  .footer-contact img {
    margin: auto auto 2rem;
  }

  .footer-contact, .footer-quick-links {
    margin: 0 0 1em;
    text-align: center;
  }

  .footer-contact p, .footer-quick-links p {
    padding: 0 0 1em;
    font-size: 14px;
    text-align: center;
  }

  .footer-contact img {
    height: 74px;
  }

  .footer-contact p a, .footer-quick-links  a {
    font-size: 14px;
    text-align: center;
  }

  .footer-links a {
    padding: 0 0 0 0;
  }

  .footer-quick-links .footer-download-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .footer-quick-links .social-cont{
    display: flex;
    justify-content: center;
  }
  

  /* .footer-quick-links {
    .subscribe-label {
      text-align: center;
      padding: 3em 0 1em;
    }

    .social-cont {
      margin: 1em 0 0;
      text-align: center;

      a {
        margin: 0 0.5em;
      }
    }

    .footer-download-links {
      a {
        text-align: center;
        margin: 0 auto 1rem;
      }
    }

    h4 {
      font-size: 16px;
    }
  }
} */
}