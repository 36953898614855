.main-app {
  box-sizing: border-box;
}

.main-app-content {
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px 0px #00000029;
  -webkit-box-shadow: 0px 3px 6px 0px #00000029;
  -moz-box-shadow: 0px 3px 6px 0px #00000029;
}

.main-app-footer {
  background-color: #171717 !important;
  color: #ffffff;
}

button:focus,
input:focus {
  outline: none;
}

.hidden {
  display: none !important;
}

/* Dragula Absolute position tr element */
.gu-mirror {
  background-color: rgba(16, 142, 233, 0.15);
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: Poppins;
}

.gu-mirror .ant-table-cell:nth-child(1) {
  /* background-color: red; */
  padding: 16px 16px;
  padding-left: 35px;
  /* width: 60vw; */
}

.gu-mirror .ant-table-cell:nth-child(2) {
  /* background-color: red; */
  padding: 16px 16px;
}

.gu-mirror .ant-table-cell:nth-child(3) {
  /* background-color: red; */
  padding: 16px 16px;
}

.gu-mirror .ant-table-cell:nth-child(4) {
  /* background-color: red; */
  padding: 16px 16px;
  display: none;
}

.gu-mirror .ant-table-cell:nth-child(5) {
  /* background-color: red; */
  padding: 16px 16px;
  display: none;
}

.gu-mirror .ant-table-cell:nth-child(6) {
  /* background-color: red; */
  padding: 16px 16px;
  display: none;
}

.gu-mirror .ant-table-cell:nth-child(7) {
  /* background-color: red; */
  padding: 16px 16px;
  display: none;
}

.gu-mirror .ant-table-cell:nth-child(8) {
  /* background-color: red; */
  padding: 16px 16px;
  display: none;
}

.ant-image-preview-img {
  display: inline;
}
