html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}
input {
  border: 0;
  outline: 0;
}
input:focus {
  outline: none !important;
}
textarea {
  border: 0;
  outline: 0;
  resize: none;
}
textarea:focus {
  outline: none !important;
}

select {
  cursor: pointer;
  font-weight: 500;

  /* padding: 10px;
  padding-right: 30px; */
  /* padding: 10px; */
  padding-right: 30px;
  padding-left: 7px;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
    background-size: 1.3em auto, 100%;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
