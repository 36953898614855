input {border:0;outline:0;}
input:focus {outline:none!important;}

#pagination_container_availability_consult_later {
  display: none !important;
}

@media (max-width: 600px) {
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    margin: 0px;
    padding: 2px 4px;
  } 

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value{
    padding-right: 4px;
  } 

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date span{
    font-size: 12px;
  } 
  
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content::-webkit-scrollbar {
    display: none;
  } 

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-content th {
    padding: 4px 8px 4px 0px;
  }
}